// данная логика необходима только для отслеживания переходов через Gatsby Link
export const onRouteUpdate = ({ location, prevLocation }, pluginOptions) => {
  if (!prevLocation) {
    // отсутствие prevLocation указывает на начальную загрузку страницы
    // и мы не хотим отслеживать данный просмотр, так как он уже отслежен счетчиком
    return;
  }

  const { ym } = window as any;
  const { pathname, search, hash } = location;
  ym(pluginOptions.trackingId, "hit", pathname + search + hash);
};
