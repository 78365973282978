import "./src/styles/normalize.css";
import "./src/styles/index.css";

import vhCheck from "./src/helpers/vhCheck";

// Fix jumping Safari behaviour
// set --vh-offset css variable to fix vh in iOS
const isNeeded = vhCheck();
console.log("vh-check isNeeded:", isNeeded);

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== "/" && !prevLocation) {
    var body = document.querySelector("body");
    body.classList.add("preloader_active");
    var contentContainer = document.querySelector(".preloader_content");
    const content = Array.from(contentContainer.children);
    content.forEach((c, i) => {
      setTimeout(() => {
        c.classList.add("slideUp");
      }, 550 * i);
    });
    setTimeout(() => {
      body.classList.add("preloader_ready");
      setTimeout(function () {
        body.classList.remove("preloader_active");
        body.classList.remove("preloader_ready");
        content.forEach((c) => c.classList.remove("slideUp"));
      }, 500);
    }, 2600);
  }
};
